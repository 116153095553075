<template>
  <div class="comp-toc">
    <ul class="content">
      <li class="item" :class="{active: A.href==$route.hash}" v-for="(A,a) in items" @click="handleNavInPage(A.el)" :key="a">
        <i class="iconfont" :class="[A.icon]"></i>
        {{A.label}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleNavInPage(el) {
      // const top = document.querySelector(`#${el}`).offsetTop
      const top = document.querySelector(`#${el}`).getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: top - 100,
        behavior: "smooth"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.comp-toc {
  width: 100%;
  padding: 75px 0 25px;
  border-bottom: solid 1px rgba(#323436, 0.2);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 1080px;
  margin: 0 auto;
  padding: 0;
}

.item {
  list-style: none;
  position: relative;
  padding: 5px 0;
  text-decoration: none;
  color: #323436;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .iconfont {
    font-size: 28px;
    margin-right: 24px;
  }

  &::before {
    content: "";
    width: 6px;
    height: 0px;
    background-color: #0edee3;
    position: absolute;
    left: -14px;
    top: 5px;
    transition: height 0.3s;
  }

  /* &:hover,
  &.active */
  &:hover {
    &::before {
      height: 28px;
    }
  }
}
</style>