<template>
  <div className="page-about">
    <top-cover :option="{title:' ',cover:$hwcdnLink('imagehost/vrhome_topcover/about.jpg')}" />
    <toc :items="tocItems" />

    <main>
      <section v-for="(A,a) in tocItems" :key="a">
        <h2 :id="A.el" v-if="a!=3">{{A.label}}</h2>

        <section class="section a" v-if="a===0">
          <p>厦门造梦世界科技有限公司(ZMVR）位于美丽厦门软件园区内，于2013年开始涉及VR产业，属于国内最早一批进入VR行业的创新团队。经过不断的融资发展，公司已经组建国内最顶尖的VR技术研发团队，针对性的研发出了ZMVR中央控制平台和商业后台管理系统多项配套的VR应用软件、强大的内容运营团队、良好的售后服务体系、通过技术的不断革新和对市场的不断探索，越来越受到更多线下B端的青睐和好评，ZMVR现已经初步完成全国VR行业的布局，成为国内最大的vr游戏平台。</p>
        </section>

        <section class="section b" v-if="a===1">
          <div class="years">
            <div class="year" :class="{active: history_current===b}" v-for="(B,b) in history" :key="$randomKey(b)" @mouseover="history_current=b">{{B.label}}</div>
          </div>
          <ul class="content">
            <li class="item" v-for="(C,c) in history[history_current].content" :key="$randomKey(c)">{{C}}</li>
          </ul>

          <div class="block a">
            <div class="item" v-for="(D,d) in block.a" :key="$randomKey(d)">
              <span class="value">{{D.value}}</span>
              <span class="label">{{D.label}}</span>
            </div>
          </div>
        </section>

        <section class="section c" v-if="a===2">
          <p class="intro">目前全国市场VR体验馆9000多家，预计1年之内体验馆将达到1万家，未来的3-5年内市场将达到10—15万家的巨型体量，游戏月点击量达2亿次，时长达10亿分钟。造梦科技将一直专注于为线下VR体验馆提供更优质的游戏，与VR体验馆携手共同促进行业快速健康发展；并借助国内体验馆市场，努力帮助国内外VR游戏企业实现大幅盈利，迎接未来VR行业的整体爆发。<br>“梦境世界”是造梦旗下的全国性VR馆超级品牌，致力于打造集虚拟现实，娱乐，休闲为一体的线下VR娱乐综合场馆。凭借两百多家VR体验馆经营管理经验，包括设备支持，游戏支持，技术支持，售后支持，软件支持等自身优势，<br>全方位支持合作VR体验店运营、赚钱！</p>
          <h3>多重服务保障，安心创业开店</h3>

          <ul class="block b">
            <li class="item" v-for="(E,e) in block.b" :key="$randomKey(e)">
              <div class="icon-box">
                <i class="iconfont" :class="[E.icon]"></i>
              </div>
              <h4>{{E.question}}</h4>
              <div class="context">{{E.context}}</div>
            </li>
          </ul>

          <div class="btn" @click="$router.push('/contact')">合作咨询</div>
        </section>

        <section class="section d" v-if="a===3">
          <div class="title">
            <h2 :id="A.el">{{A.label}}</h2>
            <p class="intro">我们正在变得更大，更雄心勃勃，并且还有很长的路要走。我们正在寻找才华横溢的人才，<br>以帮助塑造我们的未来并使游戏变得更好。</p>
          </div>

          <ul class="jobs">
            <li class="job" :class="{active: jobs.current===f}" v-for="(F,f) in jobs.list" :key="$randomKey(f)">
              <h5 @click="jobs.current = jobs.current === f ? -1 : f">
                <span>{{F.position}}</span>
                <i class="iconfont" :class="[jobs.current === f?'icon-minus-circle':'icon-plus-circle']"></i>
              </h5>
              <section class="context" v-if="jobs.current===f">
                <div class="block" v-for="(G,g) in F.context" :key="$randomKey(g)">
                  <h6>{{G.label}}:</h6>
                  <ol class="content">
                    <li class="item" v-for="(H,h) in G.content" :key="$randomKey(h)">{{H}}</li>
                  </ol>
                </div>
                <ul class="extra" v-if="F.extra">
                  <li v-for="(I, i) in F.extra" :key="$randomKey(i)">{{I}}</li>
                </ul>
              </section>
            </li>
          </ul>

          <div class="btn" @click="$router.push('/contact')">加入我们</div>
        </section>
      </section>
    </main>

    <partner />
    <page-footer class="footer" />
  </div>
</template>

<script>
import topCover from "@/components/TopCover";
import pageFooter from "@/components/Footer";
import partner from "@/components/Partner";
import toc from "@/components/Toc";

export default {
  components: {
    topCover,
    pageFooter,
    partner,
    toc,
  },
  data() {
    return {
      tocItems: [
        {
          icon: "icon-gongsijianjie",
          label: "公司简介",
          el: "toc_intro",
        },
        {
          icon: "icon-01fazhanfazhanlicheng",
          label: "发展历程",
          el: "toc_history",
        },
        {
          icon: "icon-shangwuhezuo",
          label: "商务合作",
          el: "toc_business",
        },
        {
          icon: "icon-rencai",
          label: "工作机会",
          el: "toc_job",
        },
      ],

      history: [
        {
          label: "2016",
          content: [
            "2016年1月，造梦科技正式成立",
            "2016年6月，造梦科技VR内容分发平台正式对外发布1.0版本",
            "2016年12月，造梦平台月内容点击次数首次超100万次",
          ],
        },
        {
          label: "2017",
          content: [
            "2017年5月，造梦科技VR内容分发平台上线2.0版本",
            "2017年12月，造梦平台月内容使用时长首次超1000万分钟",
          ],
        },
        {
          label: "2018",
          content: [
            "2018年8月，造梦大空间VR方案正式对外发布",
            "2018年12月，造梦平台已上线上千款优质VR游戏，上百款独家精品VR游戏",
          ],
        },
        {
          label: "2019",
          content: [
            "2019年5月，造梦“VR联盟”品牌加盟正式对外发布",
            "2019年9月，造梦无线大空间方案正式对外发布",
          ],
        },
        {
          label: "2020",
          content: [
            "2020年5月，梦境VR云游戏平台正式上线华为应用市场",
            "2020年6月，首家梦境世界VR直营店正式开业",
            "2020年10月，梦境VR升级社交功能，实现玩家在梦境中的交流和互动",
          ],
        },
        {
          label: "未来",
          content: ["造梦人不会停下脚步，我们对梦想的追逐仍在继续......"],
        },
      ],
      history_current: 0,

      block: {
        a: [
          {
            label: "体验馆",
            value: "7000家",
          },
          {
            label: "终端",
            value: "15000台",
          },
          {
            label: "月点击量",
            value: "200万",
          },
          {
            label: "体验时长(分钟)",
            value: "1000万",
          },
        ],
        b: [
          {
            icon: "icon-kaidian",
            question: "首次创业没经验？",
            context: "选址装修，设备安装调试，人员培训到开业推广一站式服务",
          },
          {
            icon: "icon-youxi",
            question: "不懂设备游戏？",
            context:
              "专业技术团队上门安装调试设备，同步进行VR设备、游戏专业培训",
          },
          {
            icon: "icon-tuiguang1",
            question: "不懂营销推广？",
            context: "百项门店营销模式立体轰炸，线上线下推广同步支持",
          },
          {
            icon: "icon-24xiaoshi",
            question: "担心产品售后？",
            context: "所有产品整机保修，专业售后团队24h在线技术支持",
          },
          {
            icon: "icon-shangwuhezuo",
            question: "如何合作开店？",
            context: "联系造梦，咨询了解开店细节",
          },
        ],
      },

      jobs: {
        list: [],
        current: -1,
      },
    };
  },
  mounted() {
    // 获取职位数据
    fetch("/job.json")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.jobs.list = res.data;
      });
  },
  methods: {
    handleOpenJob(index) {
      this.jobs.current = this.jobs.current === index ? -1 : index;
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 1080px;
  margin: 0 auto;
  // padding-top: 124px;

  h2 {
    text-align: center;
    font-size: 30px;
    color: #1c1e20;
    line-height: 1;
    font-weight: lighter;
    margin: 120px 0 0;
  }
}

.section.a {
  margin-top: 97px;
  p {
    font-size: 18px;
    color: rgba(#323436, 0.8);
    line-height: 49px;
    text-align: center;
    margin: 0;
  }
}

.section.b {
  margin-top: 80px;
  .years {
    display: flex;
    justify-content: center;
    .year {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: solid 1px #323436;
      text-align: center;
      line-height: 60px;
      color: #323436;
      font-size: 14px;
      box-sizing: border-box;
      background-color: #fff;
      cursor: default;
      transition: border-color 0.3s, background-color 0.3s, color 0.3s;

      &:not(:last-child) {
        margin-right: 60px;
      }

      &.active {
        border-color: #0edee3;
        background-color: #0edee3;
        color: #fff;
      }
    }
  }
  .content {
    width: 100%;
    padding: 60px 100px;
    margin-top: 60px;
    background-color: rgba(#181a1b, 0.05);
    box-sizing: border-box;

    .item {
      list-style: none;
      font-size: 18px;
      color: rgba(#1c1e20, 0.8);
      line-height: 1;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }
  .block {
    margin-top: 120px;
    height: 160px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      line-height: 1;

      .value {
        font-size: 36px;
        margin-bottom: 30px;
      }
      .label {
        font-size: 18px;
      }
    }

    &::before {
      content: "";
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #0edee3;
      z-index: -1;
    }
  }
}

.section.c {
  .intro {
    text-align: center;
    font-size: 18px;
    color: rgba(#323436, 0.8);
    line-height: 49px;
    margin: 100px 0 0;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    color: #323436;
    margin: 120px 0 0;
    font-weight: lighter;
  }

  .block.b {
    display: flex;
    justify-content: space-between;
    margin: 80px 0 0;
    padding: 0;
    .item {
      width: 200px;
      height: 260px;
      background: #f6f6f6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: background-color 0.3s, box-shadow 0.3s;
      overflow: hidden;
      cursor: default;
      .icon-box {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s;
        .iconfont {
          font-size: 50px;
          color: #323436;
          line-height: 1;
        }
      }
      h4 {
        text-align: center;
        font-size: 18px;
        color: #1c1e20;
        margin-top: 60px;
        opacity: 1;
        transition: color 0.3s, opacity 0.3s;
      }
      .context {
        width: 100%;
        height: 150px;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 20px;
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        box-sizing: border-box;
        transform: translateY(100%);
        transition: transform 0.3s;
      }

      &:hover {
        background-color: #0edee3;
        box-shadow: 8px 8px 12px 0px rgba(51, 51, 51, 0.12);
        .icon-box {
          transform: translateY(-10px);

          .iconfont {
            color: #fff;
          }
        }
        h4 {
          color: #fff;
          opacity: 0;
        }
        .context {
          transform: translateY(0);
        }
      }
    }
  }

  .btn {
    width: 200px;
    height: 46px;
    border: 1px solid rgba(#0edee3, 0.3);
    line-height: 46px;
    font-size: 18px;
    color: #0edee3;
    margin: 70px auto;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
      background-color: #0edee3;
      color: #fff;
    }
  }
}

.section.d {
  margin-top: 120px;
  .title {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      color: #fff;
      margin: 0 0 50px;
    }
    .intro {
      color: rgba(#fff, 0.8);
      color: 18px;
      line-height: 49px;
      text-align: center;
      margin: 0;
    }

    &::before {
      content: "";
      width: 100vw;
      height: 100%;
      background-image: url("~@/assets/about.jpg");
      background-size: cover;
      background-position: left 50vw center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-color: #ccc;
      z-index: -1;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .jobs {
    width: 100%;
    margin-top: 120px;
    padding: 0;

    .job {
      border-bottom: 1px solid rgba(#000, 0.2);
      list-style: none;

      h5 {
        color: #181a1b;
        line-height: 1;
        margin: 0;
        cursor: pointer;
        padding: 40px 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 22px;
        }
        .iconfont {
          margin-left: auto;
          font-size: 30px;
        }
      }

      .context {
        // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        // transition: clip-path 1s;
        padding: 20px 10px 46px;
        box-sizing: border-box;
        font-size: 16px;
        color: #323436;

        .block {
          &:not(:last-child) {
            margin-bottom: 20px;
          }

          h6 {
            font-size: 16px;
            color: #323436;
            margin: 0 0 10px;
          }
          .content {
            padding-left: 20px;
            .item {
              &:not(:last-child) {
                margin-bottom: 5px;
              }
            }
          }
        }

        .extra {
          padding: 0;
          margin-top: 20px;
          list-style: none;
          li {
            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }

      &.active {
        h5 {
          color: #0edee3;
        }
        /* .context {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        } */
      }
    }
  }

  .btn {
    width: 200px;
    height: 46px;
    border: 1px solid rgba(#0edee3, 0.3);
    line-height: 46px;
    font-size: 18px;
    color: #0edee3;
    margin: 70px auto;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
      background-color: #0edee3;
      color: #fff;
    }
  }
}
</style>
