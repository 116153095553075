<template>
  <section class="top-cover" :class="{'with-cover':option.withCover}" :data-title="option.title || $route.meta.title" :style="{'background-image': `url(${option.cover})`}"></section>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => {
        return {
          title: "",
          cover: require("@/assets/page_top_cover@2x.png"),
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.top-cover {
  width: 100%;
  height: 550px;
  background-color: #ccc;
  position: relative;
  background-size: cover;
  background-position: center;

  &.with-cover {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(#000, .5);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &::after {
    content: attr(data-title);
    width: 100%;
    text-align: center;
    font-size: 36px;
    color: #fff;
    display: inline-block;
    font-weight: lighter;
    position: absolute;
    line-height: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% + 50px));
  }
}
</style>