<template>
  <footer>
    <div class="container">
      <section class="block a">
        <ul class="pages">
          <li class="pages-container" v-for="(A,a) in pages" :key="a">
            <ul>
              <li class="child-title">{{ A.label }}</li>
              <li class="child" v-for="(B,b) in A.pages" @click="handleNav(B.path)" :key="b">{{ B.label }}</li>
            </ul>
          </li>
        </ul>
        <div class="logo"></div>
      </section>
      <section class="block b">
        <p class="line a">造梦科技 版权所有</p>
        <p class="line b">Copyright@2017-{{new Date().getFullYear()}} {{host}} All Rights Reserved</p>
        <p class="line c">
          <a :href="beianIcp" target="_bla">{{/zamertech/.test(host)?'闽ICP备15024866号-1':'闽ICP备15024866号-2'}}</a>
          <a :href="wenLink" target="_bla">闽网文〔2019〕0173-011号</a>
          <a :href="wenLink" target="_bla">
            <img class="icon" :src="require('@/assets/wen.png')" alt="文化经营许可标识" />
          </a>
        </p>
      </section>

      <!-- <div class="scroll-top" v-if="showScrollTop"></div> -->

    </div>
  </footer>
</template>

<script>
export default {
  name: 'index.vue',
  data() {
    return {
      pages: [
        {
          label: '关于我们',
          pages: [
            {
              label: '公司简介',
              path: '/about'
            },
            {
              label: '发展历程',
              path: '/about'
            }
          ]
        },
        {
          label: '业务发展',
          pages: [
            {
              label: '造梦VR',
              path: '/zaomeng'
            },
            {
              label: '梦境VR',
              path: '/mengjing'
            }
          ]
        },
        {
          label: '加入我们',
          pages: [
            {
              label: '商务合作',
              path: '/about'
            },
            {
              label: '联系我们',
              path: '/contact'
            }
          ]
        },
        {
          label: '帮助中心',
          pages: [
            {
              label: '法律信息',
              path: '/'
            },
            {
              label: '相关下载',
              path: '/download'
            }
          ]
        }
      ],
      host: '',
      beianIcp: 'https://beian.miit.gov.cn/#/Integrated/recordQuery',
      wenLink:
        'http://sq.ccm.gov.cn/ccnt/sczr/service/business/emark/toDetail/703ad1ed68714408b6b68bf08c1b7b1b'
    }
  },
  mounted() {
    this.host = location.host
  },
  methods: {
    handleNav(path) {
      this.$router.push({ path })
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  padding: 60px 120px 40px;
  background-color: #333;
  box-sizing: border-box;
}

.container {
  max-width: 1520px;
  margin: 0 auto;
}

.block.a {
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;

  ul {
    padding-left: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      font-size: 14px;
    }
  }

  .pages {
    .pages-container {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 100px;
      }

      .child-title {
        color: #fff;
      }

      .child {
        color: #999;
        margin-top: 20px;
        cursor: pointer;
        transition: color 0.3s;
        position: relative;

        &:hover {
          color: #fff;
        }

        &:before {
          content: '';
          position: absolute;
          top: -5px;
          right: -5px;
          bottom: -5px;
          left: -5px;
        }
      }
    }
  }

  .logo {
    width: 375px;
    height: 48px;
    margin-top: calc((88px - 48px) / 2);
    background-image: url('~@/assets/logo_footer@2x.png');
    background-size: 100% 100%;
  }
}

.block.b {
  p {
    font-size: 14px;
  }

  .line.a {
    color: #666;
  }

  .line.b {
    color: #868686;
  }

  .line.c {
    display: flex;
    align-items: center;
    color: #868686;

    a {
      color: #868686;
      text-decoration: none;
      margin-right: 10px;

      .icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        // margin-left: 20px;
      }
    }
  }
}

.scroll-top {
  position: fixed;
  width: 44px;
  height: 44px;
  background-color: #fff;
  right: 10px;
  bottom: 100px;
  border-radius: 50%;
  border: solid 1px #ccc;
}
</style>
