<template>
  <div class="comp-partner">
    <img class="content" :src="require(`@/assets/partner${color&&'_'+color}@2x.png`)" alt="合作伙伴">
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-partner {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 120px 0;
}
.content {
  width: 1038px;
  height: auto;
  display: block;
}
</style>